import { useEffect } from "react";
import styled from "styled-components";
import { PrimaryButton } from "../../components/button/PrimaryButton";
import { Column } from "../../components/common";
import { Logo } from "../../components/common/Logo";
import { Footer } from "../Home/Home.styled";
import { RefundTable } from "./Misc.styled";

const Section = styled("span")`
  margin: 0.7rem 0;
`;

export const RefundPolicy = (props: any) => {
  useEffect(() => window.scrollTo(0, 0), []);
  const rows = [
    [
      "Free Trial",
      "Cancel your Free Trial during the Free Trial Period.",
      "Not Applicable.",
      "Write to us at payments@creativegalileo.com",
    ],
    [
      "Annual Subscriptions",
      "You may cancel your annual digital plan within the free trial duration to avoid deduction of the first payment. Post the free trial you can cancel your recurring payments to avoid further dedication in future billing cycles",
      "Not Applicable.",
      "Write to us at payments@creativegalileo.com",
    ],
    [
      "Lifetime subscriptions",
      "You may cancel your annual digital plan within the free trial duration to avoid deduction of the payment",
      "Not Applicable.",
      "Write to us at payments@creativegalileo.com",
    ],
  ];
  return (
    <>
      <Column style={{ padding: "0 1.5rem 7rem 1.5rem", overflowX: "auto" }}>
        <Logo />
        <span className="page-title" style={{ marginBottom: "1rem" }}>
          Refund Policy
        </span>
        <b>Refunds policy for one time upfront payment for activity box:</b>
        <Section>
          <div>
            <b>RETURNS:</b>
          </div>
          Activity boxes can be returned only in case of defects. The returned product should be in its original
          packaging, unused/unwashed, and in the original condition as received. To initiate a return get in touch with
          our customer service team at payments@creativegalileo.com
        </Section>
        <Section>
          <div>
            <b>SHIPPING:</b>
          </div>
          <div>Shipping is free for Activity Boxes for a limited time period. Ships in 5-7 working days.</div>
          <div>Shipping rate for subscription boxes and gift boxes is INR 149/box/delivery.</div>
        </Section>
        <Section>
          <b>Cancellations policy for recurring payments for digital subscription plan:</b>
        </Section>
        <div style={{ width: "100%", overflowX: "auto" }}>
          <RefundTable>
            <thead>
              <tr>
                <td>Subscription</td>
                <td>
                  <div style={{ width: "10rem" }}>Cancellation Policy</div>
                </td>
                <td>Refund Policy</td>
                <td>How to Cancel</td>
              </tr>
            </thead>
            <tbody>
              {rows.map((row, index) => (
                <tr key={index}>
                  {row.map((item, index) => (
                    <td>{item}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </RefundTable>
        </div>
      </Column>
      <Footer>
        <PrimaryButton onClick={() => window.close()} style={{ marginBottom: "1rem" }}>
          Close
        </PrimaryButton>
      </Footer>
    </>
  );
};

export default RefundPolicy;
