import OneYear from "./1_year.png";
import ThreeYears from "./3_years.png";
import Caption from "./caption-plan.png";
import Icon1 from "./icon1.png";
import Icon2 from "./icon2.png";
import Icon3 from "./icon3.png";
import Icon4 from "./icon4.png";
import Icon5 from "./icon5.png";
import Icon6 from "./icon6.png";
import Phone from "./phone.png";
import PlansBG from "./plans_bg.png";
import RadioDisabled from "./radio_disabled.png";
import RadioSelected from "./radio_selected.png";

const PlanImages = {
  Icon1,
  Icon2,
  Icon3,
  Icon4,
  Icon5,
  Icon6,
  Caption,
  OneYear,
  ThreeYears,
  Phone,
  RadioSelected,
  RadioDisabled,
  PlansBG,
};

export default PlanImages;
