/* eslint-disable jsx-a11y/alt-text */
import { Column, Row } from "../../../components/common";
import Colors from "../../../theme/colors";
import HomeImages from "../assets/images";
import { BenefitsTable, ColumnHeader } from "./SuperBenefits.styled";

export const SuperBenefites = () => {
  const isFreeTrialEnabled = process.env.REACT_APP_FREE_TRIAL_ENABLED === "true";
  const CloseTick = () => (
    <>
      <td>
        <img src={HomeImages.Close} style={{ width: "0.8rem", height: "0.8rem" }} />
      </td>
      <td>
        <img src={HomeImages.Tick} style={{ width: "1rem", height: "0.7rem" }} />
      </td>
    </>
  );
  return (
    <div style={{ padding: "0 1.5rem", marginTop: "1rem" }}>
      <BenefitsTable>
        <tbody>
          <tr>
            <td></td>
            <td align="center">
              <ColumnHeader>FREE</ColumnHeader>
            </td>
            <td align="center">
              <ColumnHeader style={{ backgroundColor: "#6A336B" }}>
                {isFreeTrialEnabled ? "SUPER" : "PREMIUM"}
              </ColumnHeader>
            </td>
          </tr>
          <tr>
            <td>
              <Column>
                <b>Library</b>
                <span style={{ fontWeight: 300 }}>(videos, games & more)</span>
              </Column>
            </td>
            <td>Limited</td>
            <td>Unlimited</td>
          </tr>
          <tr>
            <td>
              <b>Screen time control</b>
            </td>
            <CloseTick />
          </tr>
          <tr>
            <td>
              <Column>
                <b>Age specific curriculum</b>
                <span style={{ fontWeight: 300 }}>(math & english)</span>
              </Column>
            </td>
            <CloseTick />
          </tr>
          <tr>
            <td>
              <b>Real-time progress report</b>
            </td>
            <CloseTick />
          </tr>
          <tr>
            <td>
              <b>Learn 50+ professions</b>
            </td>
            <CloseTick />
          </tr>
        </tbody>
      </BenefitsTable>
      <div
        style={{
          background: "#6A336B",
          borderRadius: "0.5rem",
          margin: "0 -0.7rem",
          padding: "0.3rem 0.7rem",
          color: "white",
          position: "relative",
        }}
      >
        <BenefitsTable>
          <tbody>
            <tr>
              <td style={{ width: "52%" }}>
                <Column>
                  <b>SUPER ACTIVITY BOX</b>
                  <span style={{ fontWeight: 300, marginTop: "0.2rem" }}>
                    100+ Worksheets, Storybooks, Games and Puzzles
                  </span>
                </Column>
              </td>
              <td>
                <img src={HomeImages.Close} style={{ width: "0.8rem", height: "0.8rem" }} />
              </td>
              <td style={{ background: "transparent" }}>
                <img src={HomeImages.Star} style={{ width: "1.2rem", height: "1.2rem" }} />
              </td>
            </tr>
          </tbody>
        </BenefitsTable>
        <img src={HomeImages.Caption} style={{ position: "absolute", right: "-0.3rem", bottom: "0" }} />
        <span
          style={{
            position: "absolute",
            right: "0",
            bottom: "0.2rem",
            color: "white",
            fontWeight: 500,
            fontSize: "0.75rem",
          }}
        >
          HOME DELIVERED
        </span>
      </div>
      <Column
        style={{
          background: Colors.cardGradient,
          padding: "1rem",
        }}
      >
        <span style={{ fontWeight: 500, fontSize: "1.1rem", marginBottom: "0.4rem" }}>More Super Benefits</span>
        {[
          "Personalized avatar profile",
          "Early access to new characters",
          "Parent-child fun quizzes",
          "Lifetime access*",
        ].map((feature) => (
          <Row key={feature} style={{ justifyContent: "flex-start", margin: "0.1rem 0" }}>
            <img src={HomeImages.Star} style={{ width: "1.2rem", height: "1.2rem", marginRight: "1rem" }} />
            <span style={{ fontSize: "0.9rem" }}>{feature}</span>
          </Row>
        ))}
      </Column>
    </div>
  );
};
