import React from "react";
import CommonImages from "../../assets/images";

export const Logo: React.FC<React.HTMLProps<HTMLImageElement>> = (props) => (
  <img
    src={CommonImages.Logo}
    style={{
      width: "5rem",
      height: "3rem",
      objectFit: "contain",
      padding: "0.5rem",
      ...props.style,
    }}
    alt="logo"
  />
);
