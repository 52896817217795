import styled from "styled-components";

export const BenefitsTable = styled("table")`
  width: 100%;
  border-collapse: collapse;
  font-size: 0.8rem;
  tr {
    td:nth-child(1) {
      width: 50%;
    }
    td {
      padding: 0.5rem 0;
      img {
        transform: translate(0, 0.2rem);
      }
    }
    td:nth-child(n + 2) {
      text-align: center;
      color: #6f6571;
      font-weight: 500;
    }
  }
  tr:nth-child(n + 2) {
    border-bottom: 1px solid #d9d9d9;
  }
  td:nth-child(3) {
    background: #fff1e7;
  }
`;

export const ColumnHeader = styled("span")`
  background: #cacaca;
  padding: 0.2rem 0.5rem;
  color: white;
  border-radius: 0.2rem;
  font-weight: 500;
  font-size: 0.8rem;
`;
