import styled from "styled-components";
import { Column } from "../../../components/common";
import Colors from "../../../theme/colors";
import Fonts from "../../../theme/fonts";

export const PlanDetailsContainer = styled(Column)`
  background: #fff1e7;
  margin-top: -2rem;
  margin-bottom: 1rem;
  padding: 1rem;
  padding-top: 2.5rem;
  border-radius: 1rem;
  .field-title {
    color: #fe7b00;
  }

  span {
    font-family: "${Fonts.primary}";
  }
`;

export const CouponContainer = styled("div")<{ disabled: boolean }>`
  border-radius: 0.5rem;
  border: 1px solid ${(props) => (props.disabled ? "#C1C1C1" : Colors.orange)};
  color: ${(props) => (props.disabled ? "#C1C1C1" : "black")};
  padding: 0.5rem;
  margin: 0.3rem 0;
`;
