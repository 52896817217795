import { Column, Row, Rupee } from "../../../components/common";
import images from "../assets/images";
import { CouponContainer } from "./PlanDetails.styled";

/* eslint-disable jsx-a11y/alt-text */
export const Coupon: React.FC<{
  disabled: boolean;
  price: number;
  discount: number;
  selected: boolean;
  onClick?: () => void;
}> = ({ disabled, price, discount, selected, onClick }) => {
  return (
    <>
      <CouponContainer
        disabled={disabled}
        onClick={() => {
          const elem = document.getElementsByClassName("coupon_applied").item(0) as HTMLButtonElement;
          elem?.click();
          onClick && onClick();
        }}
      >
        <Row style={{ alignItems: "center" }}>
          <Column style={{ flex: "1" }}>
            <span>
              <b>
                FLAT <Rupee /> {discount} OFF
              </b>
              <span
                style={{
                  fontSize: "0.8rem",
                  marginLeft: "1.5rem",
                  marginRight: "0.5rem",
                  textDecoration: "line-through",
                }}
              >
                <Rupee /> {price}
              </span>
              <span style={{ fontSize: "0.8rem" }}>
                <b>
                  <Rupee /> {price - discount}
                </b>
              </span>
            </span>
            {/* <div style={{ fontSize: "0.8rem" }}>
              <span style={{ color: disabled ? "#C1C1C1" : "#6F6571" }}>Expires in</span>
              <span style={{ color: disabled ? "#C1C1C1" : Colors.orange }}>
                {" "}
                <b>5 hours 59 mins</b>
              </span>
            </div> */}
          </Column>
          <img
            src={disabled || !selected ? images.RadioDisabled : images.RadioSelected}
            style={{ width: "1.5rem", height: "1.5rem" }}
          />
        </Row>
      </CouponContainer>
      <button className="coupon_applied" style={{ display: "none" }} />
    </>
  );
};
