import styled from "styled-components";
import { Column } from "../../components/common";
import Fonts from "../../theme/fonts";

export const InputBox = styled(Column)`
  margin: 0.5rem 0;
  span {
    align-self: flex-start;
    font-size: 0.8rem;
    font-weight: 500;
  }
  input,
  textarea {
    height: 2.5rem;
    border: none;
    box-shadow: 0 0 0.5rem #dddddd;
    border-radius: 0.5rem;
    font-family: "${Fonts.primary}";
    font-size: 0.95rem;
    padding: 0 0.5rem;
  }
  input[type="radio"] {
    box-shadow: none;
    height: auto;
  }
`;

export const RefundTable = styled("table")`
  border-collapse: collapse;
  font-size: 0.8rem;
  overflow-x: scroll;
  td {
    border: 1px solid #d9d9d9;
    text-align: center;
    padding: 0.5rem;
  }
  thead {
    font-weight: 500;
  }
`;
