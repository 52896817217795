import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Column, InvisibleButton, Row } from "../../../components/common";
import Slider from "../../../components/slider/Slider";
import device from "../../../utils/device";
import Images from "../assets/images";
import { reviewItems } from "./reviews";
import { ReviewCard } from "./ReviewSlider.styled";

export const ReviewSlider = () => {
  return (
    <Slider
      centerMode={true}
      centerSlidePercentage={device.isMobile() ? 90 : 60}
      showArrows={false}
      showThumbs={false}
      infiniteLoop={true}
      showStatus={false}
      interval={5000}
      autoPlay={true}
      preventMovementUntilSwipeScrollTolerance={true}
      swipeScrollTolerance={50}
      onChange={(index) => {
        document.getElementById(`testimonial_${index + 1}`)?.click();
      }}
    >
      {reviewItems.map((item, index) => {
        return (
          <div key={item.name}>
            <ReviewCard>
              <Row style={{ alignItems: "center", width: "100%" }}>
                <Column style={{ alignItems: "center", width: "6rem" }}>
                  <img src={item.image} alt={item.name} style={{ width: "4rem", height: "4rem" }} />
                  <span style={{ fontWeight: 700, marginBottom: "0.1rem" }}>{item.name}</span>
                  <Row>
                    {Array(5)
                      .fill(0)
                      .map((_, index) => (
                        <img
                          key={index}
                          src={Images.StarFilled}
                          alt="star"
                          style={{
                            width: "0.5rem",
                            height: "0.5rem",
                            margin: "0 0.1rem",
                          }}
                        />
                      ))}
                  </Row>
                </Column>
                <div
                  style={{
                    fontSize: "1.2rem",
                    lineHeight: "1.6rem",
                    flex: "1",
                  }}
                >
                  <span style={{ fontWeight: 500 }}>'{item.descriptionHeading}'</span>
                  {/* <span>{item.description}</span> */}
                </div>
              </Row>
            </ReviewCard>
            <InvisibleButton id={`testimonial_${index + 1}`} />
          </div>
        );
      })}
    </Slider>
  );
};
