import styled from "styled-components";
import { Row } from "../../components/common";
import Colors from "../../theme/colors";

export const OfferCountdownContainer = styled(Row)`
  align-items: center;
  background: ${Colors.yellow};
  color: white;
  padding: 0.5rem;
  border-radius: 0.5rem;
  .time-label {
    font-size: 0.7rem;
  }
  .time-column {
    align-items: center;
    margin: 0 0.3rem;
  }
  .time-value {
    font-size: 1.3rem;
    font-weight: 500;
    color: black;
    background: white;
    text-align: center;
    border-radius: 0.3rem;
    height: 2rem;
    width: 1.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.1rem;
  }
`;

export const Checkbox = styled("input")`
  width: 1.2rem;
  height: 1.2rem;
  accent-color: black;
`;
