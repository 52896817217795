import Images from "../assets/images";

export const reviewItems = [
  {
    name: "Ritika C",
    info: "Parent of a 5 year old",
    title: "MEANINGFUL SCREEN TIME",
    image: Images.Parent1,
    rating: 5,
    descriptionHeading: "Never seen my child more excited to read",
    description: " Great storybooks.",
  },
  {
    name: "Nancy M",
    info: "Parent of a 7 year old",
    title: "A MUST HAVE FOR EVERY PARENT",
    image: Images.Parent2,
    rating: 5,
    descriptionHeading: "A must download for every parent",
    description: " for parents looking for controlled screen time.",
  },
  {
    name: "Riya T",
    info: "Parent of a 5 year old",
    title: "LOVE THE PROGRESSIVE ACTIVITIES",
    image: Images.Parent3,
    rating: 5,
    descriptionHeading: "My child aced all school entrance exams",
    description: " Thank you little singham.",
  },
  {
    name: "Shefali K",
    info: "Parent of a 4 year old",
    title: "MY KG KID IS READING LIKE A 2ND GRADER",
    image: Images.Parent4,
    rating: 5,
    descriptionHeading: "My child speaks difficult words like a pro",
    description: " great app & workbook combo.",
  },
  {
    name: "Vikas S",
    info: "Parent of a 4 year old",
    title: "LITTLE SINGHAM IS A PARENTS DREAM",
    image: Images.Parent5,
    rating: 5,
    descriptionHeading: "I dont need to sit with my child while she learns",
    description: " Little singham does it for me",
  },
];
