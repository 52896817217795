import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import styled from "styled-components";
import Fonts from "../../theme/fonts";

const PhoneInputWrapper = styled("div")`
  .phone-field {
    min-height: 3rem;
    box-shadow: 0 0 0.5rem #eeeeee;
    border-radius: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    font-size: 18px !important;
    font-weight: 600;
  }

  .react-tel-input .form-control {
    border: none !important;
    font-weight: 600;
    padding-left: 64px !important;
    font-family: "${Fonts.primary}";
  }
  .react-tel-input .flag-dropdown {
    border: none !important;
    border-right: 1px solid #bdbdbd !important;
    background-color: transparent !important;
  }
  .react-tel-input .selected-flag {
    width: 58px !important;
    padding-left: 10px !important;
  }
  .react-tel-input .selected-flag .arrow {
    border-left: 6px solid transparent !important;
    border-right: 6px solid transparent !important;
    border-top: 7px solid #fe7b00 !important;
  }
`;

export const PhoneNumberInput: React.FC<{
  phoneNumber: string;
  onPhoneNumberChanged: (phoneNumber: string) => void;
}> = ({ phoneNumber, onPhoneNumberChanged }) => {
  return (
    <PhoneInputWrapper>
      <PhoneInput
        onlyCountries={["in"]}
        containerClass="phone-field"
        inputClass="phone-field"
        placeholder="Phone Number"
        country="in"
        countryCodeEditable={false}
        value={phoneNumber}
        onChange={onPhoneNumberChanged}
      />
    </PhoneInputWrapper>
  );
};
