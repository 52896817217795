import { PrimaryButton } from "../../components/button/PrimaryButton";
import { Footer } from "../Home/Home.styled";

const PrivacyPolicy = () => {
  return (
    <>
      <iframe
        title="Privacy Policy"
        src="https://s3.ap-south-1.amazonaws.com/useragreement.creativegalileo.com/privacy-policy.html"
        width="100%"
        style={{ border: "none", height: "100vh", paddingBottom: "5rem" }}
      />
      <Footer>
        <PrimaryButton onClick={() => window.close()} style={{ marginBottom: "1rem" }}>
          Close
        </PrimaryButton>
      </Footer>
    </>
  );
};

export default PrivacyPolicy;
