import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./App.css";
import Home from "./pages/Home";
import PrivacyPolicy from "./pages/Misc/PrivacyPolicy";
import RefundPolicy from "./pages/Misc/RefundPolicy";
import TermsAndConditions from "./pages/Misc/TermsAndConditions";
import { ThankYou } from "./pages/Misc/ThankYou";
import Plans from "./pages/Plans";

function App() {
  useEffect(() => {
    function startGA(w: any, d: any, s: any, l: any, i: any) {
      w[l] = w[l] || [];
      w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l !== "dataLayer" ? "&l=" + l : "";
      j.async = true;
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
      f.parentNode.insertBefore(j, f);
    }
    setTimeout(() => startGA(window, document, "script", "dataLayer", "GTM-MPNZ8L3"), 4000);
  }, []);
  return (
    <BrowserRouter>
      <div style={{ width: "100%", display: "flex" }}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/plans" element={<Plans />} />
          <Route path="/thank-you" element={<ThankYou />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
