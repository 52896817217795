import { Carousel } from "react-responsive-carousel";
import styled from "styled-components";
import Colors from "../../theme/colors";

const Slider = styled(Carousel)`
  .carousel {
    padding-bottom: 2rem;
    .control-dots .dot {
      width: 0.7rem;
      height: 0.7rem;
      margin: 0 0.2rem;
      background: #d6d6d6;
      box-shadow: none;
    }
    .dot.selected {
      background: ${Colors.primary};
    }
  }
`;

export default Slider;
