import ReactModal from "react-modal";
import { Bullet, Column, Row } from "../../../components/common";
import { modalStyles } from "../../../components/modal/Modal";
import Colors from "../../../theme/colors";
import images from "../assets/images";

interface LineItem {
  color: string;
  title?: string;
  subTitle: string;
}
const freeTrialModeItems: LineItem[] = [
  {
    color: "#FFB1EE",
    title: "Today: Unlock Pro Features",
    subTitle:
      "Unlock full access to all Little Singham features + get a Super Activity Box delivered at your door step",
  },
  {
    color: "#3EAEFF",
    title: "Day 12",
    subTitle: "Get notified when your trial is about to end.",
  },
  {
    color: "#FFB1EE",
    title: "Day 14",
    subTitle: "You account will be charged for further access to features unless you cancel before 24 hours",
  },
];

const refundModeItems: LineItem[] = [
  {
    color: "#FFB1EE",
    subTitle: "Pay Now and immediately unlock full access to our premium plan",
  },
  {
    color: "#3EAEFF",
    subTitle:
      "In case you change your mind in the next 14 days, you can write to us at payments@creativegalileo.com or whatsapp us at 7304941106 requesting full refund",
  },
  {
    color: "#FFB1EE",
    subTitle:
      "Your refund will be processed immediately from our end. In 90% cases you can see the refund amount within 24 hours in your account. In a few cases it might take upto 5 working days to reflect",
  },
];

export const FreeTrialInfoModal: React.FC<{ open: boolean; onClose: () => void }> = ({ open, onClose }) => {
  const isFreeTrialEnabled = process.env.REACT_APP_FREE_TRIAL_ENABLED === "true";
  return (
    <ReactModal isOpen={open} style={modalStyles}>
      <img
        src={images.Close}
        style={{ width: "1rem", position: "absolute", right: "0.5rem", top: "0.5rem" }}
        alt="close"
        onClick={onClose}
      />
      <Column>
        <span className="page-title" style={{ marginBottom: "1rem" }}>
          How this works?
        </span>
        {(isFreeTrialEnabled ? freeTrialModeItems : refundModeItems).map((item, index) => (
          <Row key={index} style={{ margin: "0.7rem 0" }}>
            <Bullet color={item.color} size={0.8} />
            <Column style={{ flex: 1, fontSize: "0.9rem" }}>
              {item.title && <span style={{ fontWeight: 500 }}>{item.title}</span>}
              <span style={{ color: "#6F6571", fontWeight: 400 }}>{item.subTitle}</span>
            </Column>
          </Row>
        ))}
        <div
          style={{
            padding: "1rem",
            background: Colors.cardGradient,
            marginTop: "1rem",
          }}
        >
          <span style={{ fontSize: "0.9rem", lineHeight: "1rem", fontWeight: 500, color: "#383838" }}>
            Please note: Part payment for the super activity box are non-refundable
          </span>
        </div>
      </Column>
    </ReactModal>
  );
};
