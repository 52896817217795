const Colors = {
  primary: "#FFB800",
  orange: "#FF7700",
  purple: "#6A336B",
  pink: "#C5054E",
  yellow: "#FBB800",
  cardGradient: "linear-gradient(180deg, #FFF1E7 0%, rgba(255, 241, 231, 0) 100%)",
};

export default Colors;
