import styled from "styled-components";
import { Column } from "../../components/common";

export const Footer = styled(Column)`
  width: 100%;
  position: fixed;
  bottom: 0;
  align-items: center;
  background: #ffffffdd;
  padding-top: 1rem;
`;
