export interface PlanConfig {
  type: PlanType;
  price: number;
  featurePrice: number;
  superPrice: number;
  monthlyPrice?: number;
  discount: number;
}

export const allPlans: PlanConfig[] = [
  {
    type: "monthly",
    price: 499,
    featurePrice: 499,
    superPrice: 0,
    discount: 0,
  },
  {
    type: "annual",
    price: 999,
    monthlyPrice: 83,
    discount: 150,
    featurePrice: 999,
    superPrice: 0,
  },
  {
    type: "lifetime",
    price: 2199,
    discount: 300,
    featurePrice: 1700,
    superPrice: 499,
  },
];

export type PlanType = "lifetime" | "annual" | "basic-annual" | "monthly";
