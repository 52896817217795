import { initializeApp } from 'firebase/app';
import { ConfirmationResult, getAuth, RecaptchaVerifier, signInWithPhoneNumber, UserCredential } from 'firebase/auth';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
};

initializeApp(firebaseConfig);
export const firebaseAuth = {
  auth: getAuth(),
  signIn: async (phoneNumber: string, captchaVerifier: RecaptchaVerifier): Promise<ConfirmationResult> => {
    return await signInWithPhoneNumber(getAuth(), phoneNumber, captchaVerifier);
  },
  confirm: async (otp: string, result: ConfirmationResult): Promise<UserCredential> => {
    return await result.confirm(otp);
  },
  logout: () => {
    getAuth().signOut();
  },
  getToken: () => getAuth().currentUser?.getIdToken(),
};
