import styled from "styled-components";
import Fonts from "../../theme/fonts";
import device from "../../utils/device";

export const PrimaryButton = styled("button")`
  width: ${device.isMobile() ? "90%" : "20rem"};
  height: 3.5rem;
  background: #ff7a00;
  border-radius: 0.5rem;
  font-family: "${Fonts.primary}";
  color: white;
  font-weight: 500;
  font-size: 1.1rem;
  border: none;
  :disabled {
    opacity: 0.6;
  }
`;

export const Link = styled("span")`
  cursor: pointer;
  font-weight: 700;
  text-decoration: underline;
`;
