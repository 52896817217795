import styled from "styled-components";

export const ReviewCard = styled("div")`
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  border-radius: 0.5rem;
  margin: 1rem 0.5rem;
  display: flex;
  height: 9rem;
  flex-direction: column;
  align-items: center;
  padding: 1rem 1rem;
`;
