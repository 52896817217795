import AllCharacters from "./all-characters.png";
import BalGanesh from "./bal-ganesh.webp";
import BusinessStandardLogo from "./business-standard.webp";
import Caption from "./caption.png";
import Caret from "./caret.png";
import Chakra from "./chakra.webp";
import Close from "./close.png";
import EconomicTimesLogo from "./economic_times.png";
import ForbesLogo from "./forbes-india.webp";
import Joy from "./joy.webp";
import Krishna from "./krishna.webp";
import LittleSingham from "./little-singham.webp";
import Parent1 from "./parent1.webp";
import Parent2 from "./parent2.webp";
import Parent3 from "./parent3.webp";
import Parent4 from "./parent4.webp";
import Parent5 from "./parent5.webp";
import PlayStoreLogo from "./play-store.png";
import AppRating from "./rating.png";
import Shaktiman from "./shaktiman.webp";
import StarEmpty from "./star-empty.png";
import StarFilled from "./star-fill.png";
import Star from "./star.png";
import Suppundi from "./suppundi.webp";
import ThePrintLogo from "./the-print.webp";
import Tick from "./tick.png";
import TimesNowLogo from "./times-now.webp";
import TopBackground from "./top-bg.png";
import YourStoryLogo from "./your-story.webp";

const HomeImages = {
  Parent1,
  Parent2,
  Parent3,
  Parent4,
  Parent5,
  StarFilled,
  StarEmpty,
  BusinessStandardLogo,
  ThePrintLogo,
  ForbesLogo,
  YourStoryLogo,
  TimesNowLogo,
  EconomicTimesLogo,
  BalGanesh,
  Chakra,
  Joy,
  Krishna,
  LittleSingham,
  Shaktiman,
  Suppundi,
  TopBackground,
  AllCharacters,
  Star,
  AppRating,
  PlayStoreLogo,
  Close,
  Tick,
  Caret,
  Caption,
};

export default HomeImages;
