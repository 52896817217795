/* eslint-disable jsx-a11y/alt-text */
import { RecaptchaVerifier } from "@firebase/auth";
import axios from "axios";
import { ConfirmationResult } from "firebase/auth";
import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import "react-phone-input-2/lib/style.css";
import CommonImages from "../../../assets/images";
import { Link, PrimaryButton } from "../../../components/button/PrimaryButton";
import { Column, InvisibleButton, Row } from "../../../components/common";
import { PhoneNumberInput } from "../../../components/common/PhoneNumberInput";
import { firebaseAuth } from "../../../firebase/firebase";
import Colors from "../../../theme/colors";
import device from "../../../utils/device";
import { InputBox } from "../../Misc/Misc.styled";
import { Checkbox } from "../../Plans/Plans.styled";

export const PhoneNumberModal: React.FC<{ open: boolean; onClose: (phoneNumber: string | null) => void }> = ({
  open,
  onClose,
}) => {
  const [phoneNumber, onPhoneNumberChanged] = useState(""),
    [otp, setOtp] = useState(""),
    [termsAccepted, setTermsAccepted] = useState(true),
    [error, setError] = useState(""),
    [captchaVerifier, setCaptchaVerifier] = useState<RecaptchaVerifier>(),
    [sendingOtp, setSendingOtp] = useState(false),
    [otpResult, setOtpResult] = useState<ConfirmationResult | null>(null),
    [verifyingOtp, setVerifyingOtp] = useState(false);

  const verifyOTP = () => {
      setVerifyingOtp(true);
      setError("");
      otpResult &&
        firebaseAuth
          .confirm(otp, otpResult)
          .then(() => {
            setVerifyingOtp(false);
            document.getElementById("mobile_no_entered")?.click();
            setError("");
            axios.post("https://sheet.best/api/sheets/888e75a3-d80c-4333-a3c8-16fb2c3c7a1c", {
              "Mobile Number": "+" + phoneNumber,
              "Whatsapp consent": termsAccepted ? "Yes" : "No",
              Timestamp: new Date().toString(),
              Verified: "Yes",
            });
            onClose(phoneNumber);
          })
          .catch((e) => {
            setVerifyingOtp(false);
            console.log(e);
            setError("Please enter valid OTP");
          });
    },
    sendOTP = () => {
      setSendingOtp(true);
      setError("");
      const verifier = captchaVerifier || newCaptchaVerifier();
      verifier
        .verify()
        .then(() => {
          firebaseAuth
            .signIn(`+${phoneNumber}`, verifier)
            .then((r) => {
              setSendingOtp(false);
              setOtpResult(r);
            })
            .catch((e) => {
              setSendingOtp(false);
              console.log(e);
              // @ts-ignore: Unreachable code error
              window.grecaptcha.reset();
              setError("Failed to send OTP. Please try after some time.");
            });
        })
        .catch((e) => console.log(e));
    },
    onConfirmClicked = () => {
      if (phoneNumber.length !== 12) {
        setError("Please enter valid phone number");
      } else {
        document.getElementById("otp_initiated")?.click();
        sendOTP();
      }
    },
    onChangeMobile = () => {
      setOtpResult(null);
      setOtp("");
    },
    onResendOTP = () => {
      sendOTP();
    };

  useEffect(() => {
    if (phoneNumber.length === 12) {
      axios.post("https://sheet.best/api/sheets/888e75a3-d80c-4333-a3c8-16fb2c3c7a1c", {
        "Mobile Number": "+" + phoneNumber,
        "Whatsapp consent": "-",
        Timestamp: new Date().toString(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumber]);

  const newCaptchaVerifier = () =>
    new RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: () => {},
      },
      firebaseAuth.auth
    );

  useEffect(() => {
    setCaptchaVerifier(newCaptchaVerifier());
  }, []);

  return (
    <ReactModal
      isOpen={open}
      style={{
        content: {
          width: device.isMobile() ? "80%" : "30rem",
          transform: "translate(-50%, 0)",
          border: `1px solid ${Colors.orange}`,
          inset: "auto 50% 0",
          borderRadius: "1rem",
          background: "#FFF1E7",
          marginBottom: "1rem",
        },
        overlay: {
          background: "#00000099",
          zIndex: 100,
        },
      }}
    >
      <Column style={{ alignItems: "center" }}>
        <img src={CommonImages.CloseButton} style={{ width: "1rem", alignSelf: "end" }} onClick={() => onClose(null)} />
        <span style={{ textAlign: "center", fontWeight: 400, marginBottom: "1rem" }}>
          Want to know more about our plans?
          <br /> Help us with your contact details
        </span>
        {!otpResult ? (
          <>
            <span style={{ marginBottom: "0.5rem", alignSelf: "start" }}>
              <b>Mobile Number:</b>
            </span>
            <PhoneNumberInput phoneNumber={phoneNumber} onPhoneNumberChanged={onPhoneNumberChanged} />
          </>
        ) : (
          <InputBox style={{ width: "100%" }}>
            <span>OTP:</span>
            <input onChange={(e) => setOtp(e.target.value)} placeholder="Enter OTP" />
          </InputBox>
        )}
        <span style={{ color: "red", marginTop: "0.5rem" }}>{error}</span>
        {otpResult && (
          <Column style={{ alignItems: "center", marginTop: "0.5rem" }}>
            <Link style={{ color: Colors.orange }} onClick={onChangeMobile}>
              Change mobile number
            </Link>
            <Link style={{ color: Colors.orange, marginTop: "0.5rem" }} onClick={onResendOTP}>
              Resend OTP
            </Link>
          </Column>
        )}
        {!otpResult ? (
          <PrimaryButton
            style={{ marginTop: "2rem", marginBottom: "1rem" }}
            onClick={onConfirmClicked}
            disabled={sendingOtp}
          >
            {sendingOtp ? "Sending OTP..." : "Confirm"}
          </PrimaryButton>
        ) : (
          <PrimaryButton
            style={{ marginTop: "2rem", marginBottom: "1rem" }}
            onClick={verifyOTP}
            disabled={verifyingOtp}
          >
            {verifyingOtp ? "Verifying OTP..." : "Verify"}
          </PrimaryButton>
        )}
        <Row style={{ alignItems: "center", padding: "1rem" }}>
          <Checkbox
            style={{ width: "2rem" }}
            type="checkbox"
            checked={termsAccepted}
            onChange={(e: any) => setTermsAccepted(e.target.checked)}
          />
          <div style={{ marginLeft: "0.5rem" }}>
            <span style={{ marginRight: "0.3rem", fontSize: "0.8rem", color: "#6F6571" }}>
              I allow Creative Galileo to send me notifications over Whatsapp
            </span>
          </div>
        </Row>
      </Column>
      <InvisibleButton id="mobile_no_entered" />
      <InvisibleButton id="otp_initiated" />
    </ReactModal>
  );
};
