import ReactModal from "react-modal";
import device from "../../utils/device";

ReactModal.setAppElement("#root");

export const modalStyles = {
  content: {
    width: device.isMobile() ? "80%" : "30rem",
    top: "50%",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    border: "none",
    inset: "50% 50% auto",
  },
  overlay: {
    background: "#00000099",
  },
};
