import styled from "styled-components";
import images from "../../pages/Home/assets/images";

const Row: React.FC<React.HTMLAttributes<HTMLDivElement>> = (props) => {
  const { children, style, ...remainingProps } = props;
  return (
    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", ...style }} {...remainingProps}>
      {children}
    </div>
  );
};

const Column: React.FC<React.HTMLAttributes<HTMLDivElement>> = (props) => {
  const { children, style, ...remainingProps } = props;
  return (
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", ...style }} {...remainingProps}>
      {children}
    </div>
  );
};

const Caret: React.FC<{ expanded: boolean }> = ({ expanded }) => (
  <img
    src={images.Caret}
    style={{
      width: "0.8rem",
      height: "0.5rem",
      marginLeft: "0.5rem",
      transform: `rotate(${expanded ? "180" : "0"}deg)`,
    }}
    alt="caret"
  />
);

const Line = styled("hr")`
  height: 1px;
  background: #d9d9d9;
  border: none;
`;

const Rupee = () => <span style={{ fontFamily: "Arial" }}>₹</span>;

const InvisibleButton: React.FC<{ id: string }> = ({ id }) => (
  <button id={id} className={id} style={{ display: "none" }} />
);

const Bullet: React.FC<{ color: string; size: number } & React.HTMLAttributes<HTMLDivElement>> = (props) => (
  <div
    style={{
      width: `${props.size}rem`,
      height: `${props.size}rem`,
      background: props.color,
      borderRadius: `${props.size / 2}rem`,
      marginTop: "0.4rem",
      marginRight: "1rem",
      ...props.style,
    }}
  />
);

export { Row, Column, Line, Caret, Rupee, InvisibleButton, Bullet };
